import { useContext } from "react";

import LoggedInHome from "./LoggedInHome";
import LoggedOutHome from "./LoggedOutHome";

import { UserContext } from './UserContext.js';

export default function Home() {
    const user = useContext(UserContext);

    return (
        <>
            {
                (user && user.id) ?
                    <LoggedInHome /> :
                    <LoggedOutHome />
            }
        </>
    )
}
