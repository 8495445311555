import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function LogOut() {
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem('rates-token');
        navigate('/')
    }, [navigate]);
    return (
        <></>
    )
}
