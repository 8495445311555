import './App.css';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import LogInFromEmail from './LogInFromEmail';
import AllRates from './AllRates';
import GenerateRate from './GenerateRate';
import LogOut from './LogOut';
import LogIn from './LogIn';
import SubmitRate from './SubmitRate';
import SignUp from './SignUp';

import Root from './Root';
import Home from './Home';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "generate-rate",
        element: <GenerateRate />,
      },
      {
        path: "rate-result",
        element: <>result</>,
      },
      {
        path: "submit-rate",
        element: <SubmitRate />
      },
      {
        path: "submit-thank-you",
        element: <>thanks</>,
      },
      {
        path: "all-rates",
        element: <AllRates />,
      },
      {
        path: "log-out",
        element: <LogOut />,
      },
      {
        path: "log-in",
        element: <LogIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "log-in-from-email",
        element: <LogInFromEmail />,
      },
    ]
  },
]);

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
