import { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom"
import { CircularProgress } from "@mui/material";
import { BaseUrl } from "./constants";

export default function LogInFromEmail() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const token = searchParams.get('token');

    useEffect(() => {
        const exchange = async () => {
            const response = await fetch(`${BaseUrl}/token-exchange`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });
            const data = await response.json();
            if (data.token) {
                localStorage.setItem('rates-token', data.token);
                navigate('/');
            }
        }
        exchange();
    }, [token, navigate]);

    return (
        <CircularProgress />
    )
}
