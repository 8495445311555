import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, TextField, Button } from "@mui/material";
import { BaseUrl } from "./constants";
import { UserContext } from "./UserContext";

export default function SignUp() {
    const user = useContext(UserContext);
    const navigate = useNavigate()
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const submit = async () => {
        setLoading(true);
        const response = await fetch(`${BaseUrl}/signup`, {
            method: 'POST',
            body: JSON.stringify({ email }),
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const data = await response.json();
        console.log(data);
        setLoading(false);
        setSubmitted(true);
    }

    if (user && user.id) return navigate('/')

    if (loading) return <CircularProgress />

    if (submitted) return <>thanks for signing up. check your email for a link to log in.</>

    return (
        <>
            <TextField
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                value={email}
            />
            <Button disabled={!email} onClick={submit}>Submit</Button>
        </>
    )
}
