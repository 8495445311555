import { useState } from "react";
import { Link } from "react-router-dom";

import { CircularProgress } from '@mui/material';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { BaseUrl } from "./constants";

export default function GenerateRate() {
    const [clientType, setClientType] = useState('');
    const [clientSize, setClientSize] = useState('');
    const [usage, setUsage] = useState(new Set());
    const [deliverImages, setDeliverImages] = useState(false);
    const [deliverVideos, setDeliverVideos] = useState(false);
    const [yearsExperience, setYearsExperience] = useState('');
    const [disability, setDisability] = useState('');
    const [loading, setLoading] = useState(false);
    const [imageCount, setImageCount] = useState('');
    const [videoCount, setVideoCount] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [rate, setRate] = useState('');

    const setClientTypeCheck = (value) => () => {
        if (clientType === value) setClientType('');
        else setClientType(value);
    }

    const setClientSizeCheck = (value) => () => {
        if (clientSize === value) setClientSize('');
        else setClientSize(value);
    }

    const setUsageCheck = (value) => () => {
        if (usage.has(value)) {
            setUsage(prev => new Set([...prev].filter(x => x !== value)))
        }
        else setUsage(prev => new Set(prev.add(value)))
    }

    const setYearsExperienceCheck = (value) => () => {
        if (yearsExperience === value) setYearsExperience('');
        else setYearsExperience(value);
    }

    const setDisabilityCheck = (value) => () => {
        if (disability === value) setDisability('');
        else setDisability(value);
    }

    const submitable = clientType && clientSize && yearsExperience;

    const submitForm = async () => {
        setLoading(true);
        window.scrollTo(0, 0);
        const response = await fetch(`${BaseUrl}/submit-rate`, {
            method: 'POST',
            body: JSON.stringify({}),
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('rates-token')}` }
        });
        const data = await response.json();
        if (data) {
            setLoading(false);
            setSubmitted(true);
        }
    }

    if (loading) return <CircularProgress />

    if (submitted) {
        return (
            <Paper
                sx={{
                    minHeight: '400px'
                }}
                className="generate-rate-result-paper"
                elevation={3}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%'
                    }}
                >
                    <div
                        style={{
                            marginTop: '20px'
                        }}
                    >
                        Thanks for submitting your rate!
                    </div>
                    <div
                        style={{
                            marginTop: '25px'
                        }}
                    >
                        Next steps
                        <br />
                        <div><Link style={{ color: 'blue', textDecoration: 'underline' }} to="/generate-rate">Get a rate recommnedation</Link></div>
                        <br />
                        <div><Link style={{ color: 'blue', textDecoration: 'underline' }} to="/all-rates">See actual rates others charged</Link></div>
                        <br />
                        <div><Link target="_blank" style={{ color: 'blue', textDecoration: 'underline' }} to="https://app.theluupe.com/signup/creators">Join The Luupe to get more help</Link></div>
                        <br />
                    </div>
                </div>
            </Paper>
        )
    }

    return (
        <>
            <Paper
                sx={{}}
                className="generate-rate-paper"
                elevation={3}
            >
                <div
                    style={{
                        padding: '25px'
                    }}
                >
                    <h2>Client info</h2>
                    <div>
                        <div>
                            <h4
                                style={{
                                    marginTop: '10px'
                                }}
                            >Was the project with a brand directly, through an advertising agency, or another type of client?</h4>
                            <FormGroup row={true}>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={clientType === 'Brand'}
                                        onChange={setClientTypeCheck('Brand')}
                                    />}
                                    label="Brand"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={clientType === 'Agency'}
                                        onChange={setClientTypeCheck('Agency')}
                                    />}
                                    label="Agency"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={clientType === 'Other'}
                                        onChange={setClientTypeCheck('Other')}
                                    />}
                                    label="Other"
                                />
                            </FormGroup>
                        </div>
                        <div>
                            <h4
                                style={{
                                    marginTop: '10px'
                                }}
                            >What size was the client?</h4>
                            <FormGroup row={true}>
                                <FormControlLabel control={<Checkbox checked={clientSize === 'Large'} onChange={setClientSizeCheck('Large')} />} label="Large, well know" />
                                <FormControlLabel control={<Checkbox checked={clientSize === 'Small'} onChange={setClientSizeCheck('Small')} />} label="Small, local" />
                                <FormControlLabel control={<Checkbox checked={clientSize === 'Other'} onChange={setClientSizeCheck('Other')} />} label="Other" />
                            </FormGroup>
                        </div>
                        <div>
                            <h4
                                style={{
                                    marginTop: '10px'
                                }}
                            >What was the usage?</h4>
                            <FormGroup row={true}>
                                <FormControlLabel control={<Checkbox checked={usage.has('Print')} onChange={setUsageCheck('Print')} />} label="Print" />
                                <FormControlLabel control={<Checkbox checked={usage.has('Out of Home')} onChange={setUsageCheck('Out of Home')} />} label="Out of home" />
                                <FormControlLabel control={<Checkbox checked={usage.has('Social Media')} onChange={setUsageCheck('Social Media')} />} label="Social Media" />
                                <FormControlLabel control={<Checkbox checked={usage.has('Other')} onChange={setUsageCheck('Other')} />} label="Other" />
                            </FormGroup>
                        </div>
                        <div>
                            <h4
                                style={{
                                    marginTop: '10px'
                                }}
                            >What were the deliverables?</h4>
                            <FormGroup row={true}>
                                <FormControlLabel control={<Checkbox checked={deliverImages} onChange={() => setDeliverImages(!deliverImages)} />} label="Images" />
                                <FormControlLabel control={<Checkbox checked={deliverVideos} onChange={() => setDeliverVideos(!deliverVideos)} />} label="Videos" />
                            </FormGroup>
                        </div>
                        {
                            deliverImages ? (
                                <div>
                                    <h4
                                        style={{
                                            marginTop: '10px'
                                        }}
                                    >How many images?</h4>
                                    <TextField
                                        onChange={(e) => setImageCount(e.target.value)}
                                        variant="outlined"
                                        value={imageCount}
                                    />
                                </div>
                            ) : <></>
                        }
                        {
                            deliverVideos ? (
                                <div>
                                    <h4
                                        style={{
                                            marginTop: '10px'
                                        }}
                                    >How many videos?</h4>
                                    <TextField
                                        onChange={(e) => setVideoCount(e.target.value)}
                                        variant="outlined"
                                        value={videoCount}
                                    />
                                </div>
                            ) : <></>
                        }
                        <div>
                            <h4
                                style={{
                                    marginTop: '10px'
                                }}
                            >What was your day rate?</h4>
                            <TextField
                                onChange={(e) => setRate(e.target.value)}
                                variant="outlined"
                                value={rate}
                            />
                        </div>
                    </div>
                </div>
            </Paper>
            <div style={{ height: '20px' }}></div>
            <Paper
                sx={{}}
                className="generate-rate-paper"
                elevation={3}
            >
                <div
                    style={{
                        padding: '25px'
                    }}
                >
                    <h2>Personal info</h2>
                    <div>
                        <div>
                            <h4
                                style={{
                                    marginTop: '10px'
                                }}
                            >How many years of experience do you have in the industry?</h4>
                            <FormGroup row={true}>
                                <FormControlLabel control={<Checkbox checked={yearsExperience === '0-1'} onChange={setYearsExperienceCheck('0-1')} />} label="0-1" />
                                <FormControlLabel control={<Checkbox checked={yearsExperience === '1-5'} onChange={setYearsExperienceCheck('1-5')} />} label="1-5" />
                                <FormControlLabel control={<Checkbox checked={yearsExperience === '5-10'} onChange={setYearsExperienceCheck('5-10')} />} label="5-10" />
                                <FormControlLabel control={<Checkbox checked={yearsExperience === '10+'} onChange={setYearsExperienceCheck('10+')} />} label="10+" />
                            </FormGroup>
                        </div>
                        <div>
                            <h4
                                style={{
                                    marginTop: '10px'
                                }}
                            >How do you identify?</h4>
                            <FormGroup row={true}>
                                <FormControlLabel control={<Checkbox />} label="Agender" />
                                <FormControlLabel control={<Checkbox />} label="Cisgender" />
                                <FormControlLabel control={<Checkbox />} label="Female" />
                                <FormControlLabel control={<Checkbox />} label="Gender Non-conforming" />
                                <FormControlLabel control={<Checkbox />} label="Genderqueer" />
                                <FormControlLabel control={<Checkbox />} label="Male" />
                                <FormControlLabel control={<Checkbox />} label="Non-binary" />
                                <FormControlLabel control={<Checkbox />} label="Transgender" />
                                <FormControlLabel control={<Checkbox />} label="Not Listed" />
                                <FormControlLabel control={<Checkbox />} label="Prefer to self-describe" />
                                <FormControlLabel control={<Checkbox />} label="Prefer not to say" />
                            </FormGroup>
                        </div>
                        <div>
                            <h4
                                style={{
                                    marginTop: '10px'
                                }}
                            >Which categories best describe you?</h4>
                            <FormGroup row={true}>
                                <FormControlLabel control={<Checkbox />} label="American Indian or Alaskan Native" />
                                <FormControlLabel control={<Checkbox />} label="Asian or Asian American" />
                                <FormControlLabel control={<Checkbox />} label="Black or African American" />
                                <FormControlLabel control={<Checkbox />} label="Hispanic, Latinx, or Spanish Origin" />
                                <FormControlLabel control={<Checkbox />} label="Middle Eastern or North African" />
                                <FormControlLabel control={<Checkbox />} label="Native Hawaiian or Other Pacific Islander" />
                                <FormControlLabel control={<Checkbox />} label="White" />
                                <FormControlLabel control={<Checkbox />} label="Some other race, ethnicity or origin" />
                                <FormControlLabel control={<Checkbox />} label="Prefer not to say" />
                            </FormGroup>
                        </div>
                        <div>
                            <h4
                                style={{
                                    marginTop: '10px'
                                }}
                            >Do you identify as a person with a disability?</h4>
                            <FormGroup row={true}>
                                <FormControlLabel control={<Checkbox checked={disability === 'Yes'} onChange={setDisabilityCheck('Yes')} />} label="Yes" />
                                <FormControlLabel control={<Checkbox checked={disability === 'No'} onChange={setDisabilityCheck('No')} />} label="No" />
                                <FormControlLabel control={<Checkbox checked={disability === 'Other'} onChange={setDisabilityCheck('Other')} />} label="Prefer not to say" />
                            </FormGroup>
                        </div>
                        <div>
                            <h4
                                style={{
                                    marginTop: '10px'
                                }}
                            >Where are you located?</h4>
                            <TextField id="outlined-basic" label="City" variant="outlined" />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Button disabled={!submitable} onClick={submitForm}>Submit</Button>
                        </div>
                    </div>
                </div>
            </Paper>
        </>
    )
}
