import { useState, useEffect } from 'react'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { CircularProgress } from '@mui/material';

import { BaseUrl } from "./constants";

const columns = [
    { id: 'assignment', label: 'Assignment' },
    { id: 'client_type', label: 'Client Type' },
    { id: 'brand_size', label: 'Brand Size' },
    { id: 'delivered_images', label: 'Deliverables' },
    { id: 'usage', label: 'Usage' },
    { id: 'daily_rate', label: 'Rate' }
]

export default function AllRates() {
    const [rates, setRates] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const foo = async () => {
            setLoading(true);
            const response = await fetch(`${BaseUrl}/rates`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            });
            const data = await response.json();
            setRates(data);
            setLoading(false);
        }
        foo();
    }, []);
    return (
        <>
            <TableContainer sx={{ maxHeight: 440, backgroundColor: 'white' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? <CircularProgress /> : rates
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
