import { useState, useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import { Box } from "@mui/system";

import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";

import { BaseUrl } from "./constants";

import { UserContext } from './UserContext.js';

import ScrollToTop from "./ScrollToTop";

export default function Root() {
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);
    const { pathname } = useLocation();

    useEffect(() => {
        const fetchUser = async () => {
            setLoading(true);
            const response = await fetch(`${BaseUrl}/me`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('rates-token')}`
                }
            });
            const data = await response.json();
            setUser(data);
            setLoading(false);
        }
        fetchUser();
    }, [pathname]);

    return (
        <>
            <main className="main">
                <div>
                    <div>
                        <Link to="/">
                            <img alt="logo" width="100px" src="https://upload.wikimedia.org/wikipedia/commons/d/d0/The-Luupe-Logo.png" />
                        </Link>
                    </div>
                    <div>
                        <Box
                            sx={{
                                marginTop: '30px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <ScrollToTop />
                            <UserContext.Provider value={user}>
                                {loading ? <CircularProgress /> : <Outlet />}
                            </UserContext.Provider>
                        </Box>
                    </div>
                </div>
            </main>
        </>
    )
}
