import { useContext } from "react";

import { Link } from "react-router-dom";

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import { UserContext } from './UserContext.js';

const sections = [{
    title: 'See what others are charging',
    subtitle: 'Curious to see what others are charging?',
    cta: 'Go',
    link: '/all-rates',
    disabled: user => !user.contributed
}, {
    title: 'Get a rate recommendation',
    subtitle: 'Have a job coming up and need help determing what you should charge?',
    cta: 'Go',
    link: '/generate-rate',
    disabled: () => false
}, {
    title: 'Contribute your rates',
    subtitle: 'Help others by adding your rates to the database',
    cta: 'Go',
    link: '/submit-rate',
    disabled: () => false
}]

export default function LoggedInHome() {
    const user = useContext(UserContext);
    return (
        <>
            {
                sections.map(section => {
                    return (
                        <Tooltip disableHoverListener={!section.disabled(user)} title="You need to contribute rates before you can see all" placement="top">
                            <Paper
                                style={{
                                    // height: '180px',

                                    marginBottom: '20px',
                                    opacity: section.disabled(user) ? 0.4 : 1
                                }}
                                className="logged-in-home-paper"
                                elevation={3}
                            >
                                <div
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: '25px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            height: '100%'
                                        }}
                                    >
                                        <div>
                                            <h2>{section.title}</h2>
                                            <h4>{section.subtitle}</h4>
                                        </div>
                                        <div>
                                            <Button component={Link} disabled={section.disabled(user)} to={section.link} variant="outlined">{section.cta}</Button>
                                        </div>
                                    </div>
                                </div>
                            </Paper>
                        </Tooltip>
                    )
                })
            }
        </>
    )
}
